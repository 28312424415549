<template>
  <b-card title="Activities">
    <b-form>
      <validation-observer ref="simpleRules">
        <form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Responsible">
                <validation-provider #default="{ errors }" name="Responsible" rules="required">
                  <v-select v-model="responsibeSave" :options="users" label="name" placeholder="Select the responsible"
                    autofocus trim :clearable="false">
                    <template #list-header>
                      <b-button variant="outline-primary" block @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Title">
                <validation-provider #default="{ errors }" name="Title" rules="required">
                  <b-form-input v-model="titleSave" placeholder="Title">
                  </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col cols="4">
              <b-form-group label="Status">
                <validation-provider #default="{ errors }" name="Status" rules="required">
                  <v-select v-if="id" disabled placeholder="Select a status" :options="statusOpt" variant="primary"
                    v-model="selectedStatus" :clearable="false" />
                  <v-select v-else placeholder="Select a status" :options="statusOpt" variant="primary"
                    v-model="selectedStatus" :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col cols="6">
              <b-form-group label="Project">
                <validation-provider #default="{ errors }" name="Project" rules="required">
                  <v-select :options="projects" label="title" variant="primary" placeholder="Select the project"
                    v-model="selectedProject" :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Tasks">
                <v-select placeholder="Select the tasks" v-model="selectedTasks" :options="tasksTemplate" label="title"
                  multiple class="w-100">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Start date">
                <validation-provider #default="{ errors }" name="Start date" rules="required">
                  <b-form-datepicker id="start_date" v-model="startDateSave">
                  </b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="End date">
                <validation-provider #default="{ errors }" name="End date">
                  <b-form-datepicker id="end_date" v-model="endDateSave">
                  </b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="create()">
                Guardar
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                @click="$router.push({ name: 'activities' })">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-form>

    <b-modal ref="createUser" hide-footer hide-header size="lg" no-close-on-backdrop>
      <UserCreate @close="closeModal" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BFormDatepicker,
  BCard,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { activityStatusOptions } from '@/utils/status'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import UserCreate from '@/components/atoms/users/UserCreate.vue'

export default {
  components: {
    UserCreate,
    BFormDatepicker,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  watch: {
    selectedProject: {
      handler(current, old) {
        if (this.$route.name !== 'activities/edit' || (old && current && old.id !== current.id)) {
          this.selectedTasks = this.tasksTemplate.filter(
            task => task.projects.find(project => project.id === this.selectedProject.id)
          )
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      selectedStatus: { label: 'ACTIVATED', key: 'activated' },
      statusOpt: activityStatusOptions,
      selectedTasks: [],
      selectedProject: null,
      titleSave: '',
      responsibeSave: '',
      startDateSave: '',
      endDateSave: '',
      statusSave: 'START',
      required,
    };
  },

  computed: {
    users() {
      return this.$store.state.usersConfig.users ?
        this.$store.state.usersConfig.users
          .filter((user) => user.status === 1) :
        []
    },

    projects() {
      return this.$store.state.projectConfig.projects
    },

    tasksTemplate() {
      return this.selectedProject
        ? this.$store.state.taskTemplateConfig.tasksTemplate.filter(
          task => task.projects.find(project => project.id === this.selectedProject.id)
        ) : []
    },

    activity() {
      return this.$store.state.activityConfig.activity
    }
  },

  async created() {
    this.id = this.$route.params.id

    await Promise.all([
      this.$store.dispatch('getAllUsers'),
      this.$store.dispatch('getAllProjects'),
      this.$store.dispatch('getAllTaskTemplate'),
    ])

    if (this.$route.name !== 'activities/edit') return

    try {
      await this.$store.dispatch('getActivity', this.id)
    } catch {
      this.$router.push('/error-404')
    }

    this.titleSave = this.activity.title
    this.responsibeSave = this.activity.responsible
    this.startDateSave = this.activity.start_date
    this.endDateSave = this.activity.end_date
    this.selectedStatus = this.statusOpt.find(s => s.key === this.activity.status)
    this.selectedProject = this.activity.project
    this.selectedTasks = this.activity.tasksActivities ? this.activity.tasksActivities.map(task => task.task_template) : []
  },

  methods: {
    async create() {
      const isValid = await this.$refs.simpleRules.validate()
      if (isValid) {
        const data = {
          id: this.id || null,
          title: this.titleSave,
          responsible_id: this.responsibeSave.id,
          start_date: this.startDateSave,
          end_date: this.endDateSave,
          status: this.selectedStatus.key,
          fk_tasks_template: this.selectedTasks.length > 0 ? this.selectedTasks.map((task) => task.id) : [],
          fk_project: this.selectedProject.id,
        }

        if (this.$route.name !== 'activities/edit') await this.$store.dispatch('createActivity', data)
        else await this.$store.dispatch('updateActivity', data)

        this.$router.push({ name: 'activities' })
      }
    },

    closeModal() {
      this.$store.dispatch('getAllUsers')
      this.$refs['createUser'].hide()
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-departamento-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
